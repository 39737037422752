import Bugsnag from "@bugsnag/js";

const log = (message) => {
  console.log(message);
}
const error = (error) => {
  console.error(error);
  // Bugsnag.notify(error);
}
// const start = () => { console.log('starting fake bugsnag') }
const start = () => {
  console.log('ToDo Start bugsnag');
  // Bugsnag.start();
}

export default { start, log, error };