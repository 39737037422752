import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { appConfig } from 'src/config';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { SnackbarProvider } from 'notistack';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import jssPreset from '@mui/styles/jssPreset';
import StylesProvider from '@mui/styles/StylesProvider';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enGB } from 'date-fns/locale'; // 'dd/mm/yyyy hh:mm' format
import apiClient from '@plaato/api-client';
import GlobalStyles from 'src/components/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import { IntercomProvider } from 'react-use-intercom';
// import SettingsNotification from 'src/components/SettingsNotification';
import { AuthProvider } from 'src/contexts/JWTAuthContext';
import { PreferencesProvider } from 'src/contexts/PreferencesProvider';
import useSettings from 'src/hooks/useSettings';
import { createMyTheme } from 'src/theme';
import routes, { renderRoutes } from 'src/routes/adminRoutes';
import useAppUsage from 'src/hooks/useAppUsage';

require('dotenv').config();

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();

const App = () => {
  const { settings } = useSettings();

  useAppUsage(history);
  apiClient.setBaseURL(appConfig.apiBaseUrl);

  const theme = createMyTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
  });

  return (
    <StyledEngineProvider injectFirst>
      <IntercomProvider appId={appConfig.intercomKey}>
        <ThemeProvider theme={theme}>
          <StylesProvider jss={jss}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
              <SnackbarProvider dense maxSnack={3}>
                <Router history={history}>
                  <AuthProvider>
                    <PreferencesProvider>
                      <GlobalStyles />
                      <ScrollReset />
                      <GoogleAnalytics />
                      {/* <CookiesNotification /> */}
                      {/* <SettingsNotification /> */}
                      {renderRoutes(routes)}
                    </PreferencesProvider>
                  </AuthProvider>
                </Router>
              </SnackbarProvider>
            </LocalizationProvider>
          </StylesProvider>
        </ThemeProvider>
      </IntercomProvider>
    </StyledEngineProvider>
  );
};

export default App;
