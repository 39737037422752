import React from 'react';
import AnnounceKit from 'announcekit-react';
import useAuth from 'src/hooks/useAuth';
import { Typography } from '@mui/material';

const PrimaryButton = ({ icon, children, type, onClick, ...rest }) => {
  const { user } = useAuth();

  return (
    <Typography noWrap variant="h6" textAlign="center" color="textPrimary">
      <AnnounceKit widget="https://announcekit.app/widgets/v2/1ZSfYY" user={user}> What's new </AnnounceKit>
    
    </Typography>
  );
};

export default PrimaryButton;
