import axios from 'axios';
import { appConfig } from 'src/config';
import secureStorage from 'src/contexts/secureStorage';

const axiosInstance = axios.create({
  baseURL: appConfig.apiBaseUrl,
});

axiosInstance.interceptors.response.use(
  response => response
  // (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

axiosInstance.interceptors.request.use(function(config) {
  const token = secureStorage.getAccessToken();
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

export default axiosInstance;
