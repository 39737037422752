import { useState } from 'react';

export default function useApi(apiFunction, callback) {
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);

  async function request(...args) {
    setLoading(true);
    setError(undefined);
    const response = await apiFunction(...args);
    handleResponse(response);
    setLoading(false);
    return response;
  }

  function handleResponse(response) {
    if (response.ok || response.statusText === 'OK') handleRequestSuccess(response);
    else handleRequestError(response);
  }

  function handleRequestSuccess(response) {
    const { data } = response;
    setData(data);
    if (callback) callback(data);
    return data;
  }

  function handleRequestError(response) {
    const message = handleRequestErrorAndReturnMessage(response);
    setError(message);
    setData(undefined);
  }

  function handleRequestErrorAndReturnMessage(response) {
    const {
      config: { baseURL, url, method, headers },
      status,
      data,
      problem,
      duration,
    } = response;
    const cleanedHeaders = {
      ...headers,
      Authorization: headers.Authorization ? 'REDACTED' : 'NOT_DEFINED',
    };
    const logObject = JSON.stringify(
      {
        method,
        baseURL,
        url,
        headers: cleanedHeaders,
        status,
        data,
        problem,
        duration,
      },
      null,
      2
    );

    switch (problem) {
      case 'CLIENT_ERROR': {
        console.warn(`Client error: ${logObject}`);
        return 'There was an error with the request. Please try again later.';
      }
      case 'SERVER_ERROR': {
        console.error(`Server error while making request: ${logObject}`);
        return 'An error occurred on our server. Please try again later.';
      }
      case 'TIMEOUT_ERROR': {
        console.error(`Request timed out: ${logObject}`);
        return 'The request timed out. Please try again later.';
      }
      case 'CONNECTION_ERROR': {
        console.error(`Request failed to reach server: ${logObject}`);
        return 'Could not reach the server. Please try again later.';
      }
      case 'NETWORK_ERROR': {
        return 'Could not reach the internet. Please check your internet connection.';
      }
      default: {
        console.error(`Unhandled ApiSauce problem code: ${logObject}`);
        return `We encountered an unknown error. Please try again later.`;
      }
    }
  }

  return { request, data, error, loading };
}
