import React from 'react';
import clsx from 'clsx';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useAuth from '../hooks/useAuth';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    backgroundColor: theme.palette.primary.main,
    width: '100%',
  },
}));

const AnnouncementBar = ({ className, ...rest }) => {
  const classes = useStyles();
  const [closed, setClosed] = React.useState(false);
  const { isAdmin } = useAuth();

  return !closed && !isAdmin ? (
    <>
      <div className={clsx(classes.root, className)} {...rest}>
        <Box alignItems="center" style={{ backgroundColor: 'primary', padding: '4px 0' }}>
          <Typography variant="body1" color="black" align="center">
            Be the first to experience our new platform!
            <a
              style={{
                border: '1px solid black',
                borderRadius: '15px',
                marginLeft: '8px',
                padding: '2px 12px',
              }}
              href="https://app.plaato.cloud"
              target={'_blank'}
            >
              <b>Click here!</b>
            </a>
          </Typography>
          <button
            style={{ position: 'absolute', right: '10px', top: '10px', color: 'black' }}
            onClick={() => setClosed(true)}
          >
            <svg
              width="9"
              height="12"
              viewBox="0 0 7 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.74255 6.21372L4.70047 4.17164L6.74256 2.12955C6.88097 1.99114 6.95872 1.80342 6.95872 1.60769C6.95872 1.41195 6.88097 1.22423 6.74256 1.08582C6.60415 0.947413 6.41643 0.869657 6.22069 0.869657C6.02496 0.869657 5.83723 0.947413 5.69883 1.08582L3.65674 3.12791L1.61465 1.08582C1.47624 0.947411 1.28852 0.869654 1.09279 0.869654C0.897049 0.869654 0.709328 0.947411 0.57092 1.08582C0.432513 1.22423 0.354756 1.41195 0.354756 1.60768C0.354756 1.80342 0.432513 1.99114 0.57092 2.12955L2.61301 4.17164L0.570928 6.21372C0.432521 6.35213 0.354763 6.53985 0.354763 6.73559C0.354763 6.93132 0.43252 7.11904 0.570928 7.25745C0.709335 7.39586 0.897055 7.47362 1.09279 7.47362C1.28853 7.47362 1.47625 7.39586 1.61466 7.25745L3.65674 5.21537L5.69882 7.25745C5.83723 7.39586 6.02495 7.47361 6.22069 7.47361C6.41642 7.47361 6.60414 7.39586 6.74255 7.25745C6.88096 7.11904 6.95872 6.93132 6.95872 6.73558C6.95872 6.53985 6.88096 6.35212 6.74255 6.21372Z"
                fill="#1B1D29"
              />
            </svg>
          </button>
        </Box>
      </div>
    </>
  ) : null;
};

export default AnnouncementBar;
