import { List, ListSubheader } from '@mui/material';
import { HardDrive as HardDriveIcon, Users as UsersIcon } from 'react-feather';

import {
  BugIcon,
  DashboardIcon,
  DevicesIcon,
  BatchesIcon,
  RecipesIcon,
  AccountIcon,
} from 'src/components/icons';

import { matchPath } from 'react-router';
import NavItem from './NavItem';
import LocalDrinkIcon from '@mui/icons-material/LocalDrink';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';

const adminSection = [
  {
    subheader: 'Management',
    items: [
      {
        title: 'Clients',
        icon: LocalDrinkIcon,
        href: '/app/management/clients',
        items: [
          {
            title: 'List Clients',
            href: '/app/management/clients',
          },
          {
            title: 'Create Client',
            href: '/app/management/clients/create',
          },
        ],
      },
      {
        title: 'Users',
        icon: UsersIcon,
        href: '/app/management/users',
        items: [
          {
            title: 'List Users',
            href: '/app/management/users',
          },
        ],
      },
      {
        title: 'Devices',
        icon: HardDriveIcon,
        href: '/app/management/devices',
        items: [
          {
            title: 'Device Logs',
            href: '/app/management/device-logs',
          },
          {
            title: 'Device Types',
            href: '/app/management/device-types',
          },
          {
            title: 'List Devices',
            href: '/app/management/devices',
          },
          {
            title: 'Bulk Upload Devices',
            href: '/app/management/devices/bulk-upload',
          },
          {
            title: 'Create Device',
            href: '/app/management/devices/create',
          },
        ],
      },
      {
        title: 'Connects',
        icon: DeviceHubIcon,
        href: '/app/management/cloud-connects',
        items: [
          {
            title: 'List  Connects',
            href: '/app/management/cloud-connects',
          },
          {
            title: 'Bulk Upload Plaato Connectors',
            href: '/app/management/cloud-connects/bulk-upload',
          },
        ],
      },
      ,
      /*       {
        title: 'Production',
        icon: PrecisionManufacturingIcon,
        href: '/app/management/production-batches',
        items: [
          {
            title: 'List Batches',
            href: '/app/management/production-batches',
          },
          {
            title: 'Upload',
            href: '/app/management/production-batches/create',
          },
        ],
      } */ {
        title: 'Report a bug',
        icon: BugIcon,
        externalLink: true,
        href: 'https://plaato-tech.atlassian.net/secure/CreateIssue.jspa?issuetype=10010&pid=10031',
      },
      {
        title: 'Dev Request',
        icon: DashboardIcon,
        externalLink: true,
        href: 'https://plaato-tech.atlassian.net/secure/CreateIssue.jspa?issuetype=10009&pid=10031',
      },
    ],
  },
];

const sections = [
  {
    items: [
      {
        id: 'dashboard-menu-item',
        title: 'Dashboard',
        icon: DashboardIcon,
        href: '/app/dashboard',
      },
      {
        id: 'recipe-menu-item',
        title: 'Recipes',
        icon: RecipesIcon,
        href: '/app/recipes',
      },
      {
        id: 'batches-container',
        title: 'Batches',
        icon: BatchesIcon,
        href: '/app/batches',
      },
      {
        id: 'device-menu-item',
        title: 'Devices',
        icon: DevicesIcon,
        href: '/app/devices',
      },

      {
        id: 'my-team-menu-item',
        title: 'Account',
        icon: AccountIcon,
        href: '/app/account',
      },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce((acc, item) => reduceChildRoutes({ acc, item, pathname, depth }), [])}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        id={item.id}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        externalLink={item.externalLink ? item.externalLink : false}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        id={item.id}
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        externalLink={item.externalLink ? item.externalLink : false}
        title={item.title}
      />
    );
  }

  return acc;
}

const MenuOptions = ({ isAdmin, showCustomerInterface }) => {
  return (
    <>
      {!isAdmin || showCustomerInterface
        ? sections.map(section => (
            <List key="customer">
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))
        : null}
      {isAdmin && !showCustomerInterface
        ? adminSection.map(aSection => (
            <List
              key={aSection.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {aSection.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: aSection.items,
                pathname: location.pathname,
              })}
            </List>
          ))
        : null}
    </>
  );
};

export default MenuOptions;
