import React, { createContext, useContext, useEffect, useState } from 'react';

import useAuth from 'src/hooks/useAuth';
import {
  constants,
  useDensityUtils,
  usePressureUtils,
  useTemperatureUtils,
} from '@plaato/api-client';

const PreferencesContext = createContext({});

export const PreferencesProvider = ({ children }) => {
  const { user } = useAuth();
  const [densityPreference, setDensityPreference] = useState(constants.SPECIFIC_GRAVITY);
  const [temperaturePreference, setTemperaturePreference] = useState(constants.CELSIUS);
  const [pressurePreference, setPressurePreference] = useState(constants.PSI);

  const {
    setDensityUnit,
    getDensity: getDensityApiUtils,
    getDensityString: getDensityStringApiUtils,
    getDensityUnit: getDensityUnitApiUtils,
    getSpecificGravity: getSpecificGravityApiUtils,
    getFermentationActivity: getFermentationActivityApiUtils,
    getFermentationActivityUnit: getFermentationActivityUnitApiUtils,
    getFermentationActivityString: getFermentationActivityStringApiUtils,
  } = useDensityUtils();

  const {
    setTemperatureUnit,
    getTemperature: getTemperatureApiUtils,
    getTemperatureUnit: getTemperatureUnitApiUtils,
    getTemperatureString: getTemperatureStringApiUtils,
  } = useTemperatureUtils();

  const {
    setPressureUnit,
    getPressure: getPressureApiUtils,
    getPressureUnit: getPressureUnitApiUtils,
    getPressureString: getPressureStringApiUtils,
    getPSI: getPSIApiUtils,
  } = usePressureUtils();

  useEffect(preferencesUpdate, [user]);

  function preferencesUpdate() {
    if (user?.preferences?.density) {
      setDensityUnit(user.preferences.density);
      setDensityPreference(user.preferences.density);
    }

    if (user?.preferences?.temperature) {
      setTemperatureUnit(user.preferences.temperature);
      setTemperaturePreference(user.preferences.temperature);
    }

    if (user?.preferences?.pressure) {
      setPressureUnit(user.preferences.pressure);
      setPressurePreference(user.preferences.pressure);
    }
  }

  const getDensity = specificGravity => {
    setDensityUnit(densityPreference);
    return getDensityApiUtils(specificGravity);
  };

  const getDensityUnit = () => {
    setDensityUnit(densityPreference);
    return getDensityUnitApiUtils();
  };

  const getDensityString = (specificGravity, withUnit = true) => {
    setDensityUnit(densityPreference);
    return getDensityStringApiUtils(specificGravity, withUnit);
  };

  const getFermentationActivity = mSgPerHour => {
    setDensityUnit(densityPreference);
    return getFermentationActivityApiUtils(mSgPerHour);
  };

  const getFermentationActivityUnit = () => {
    setDensityUnit(densityPreference);
    return getFermentationActivityUnitApiUtils();
  };

  const getFermentationActivityString = (mSgPerHour, withUnit = true) => {
    setDensityUnit(densityPreference);
    return getFermentationActivityStringApiUtils(mSgPerHour, withUnit);
  };

  const getTemperature = celsius => {
    setTemperatureUnit(temperaturePreference);
    return getTemperatureApiUtils(celsius);
  };

  const getTemperatureUnit = () => {
    setTemperatureUnit(temperaturePreference);
    return getTemperatureUnitApiUtils();
  };

  const getTemperatureString = (temperature, withUnit = true) => {
    setTemperatureUnit(temperaturePreference);
    return getTemperatureStringApiUtils(temperature, withUnit);
  };

  const getSpecificGravity = density => {
    setDensityUnit(densityPreference);
    return getSpecificGravityApiUtils(density);
  };

  const getPressure = psi => {
    setPressureUnit(pressurePreference);
    return getPressureApiUtils(psi);
  };
  const getPressureUnit = () => {
    setPressureUnit(pressurePreference);
    return getPressureUnitApiUtils();
  };

  const getPressureString = (psi, withUnit = true) => {
    setPressureUnit(pressurePreference);
    return getPressureStringApiUtils(psi, withUnit);
  };

  const getPSI = pressure => {
    setPressureUnit(pressurePreference);
    return getPSIApiUtils(pressure);
  };

  return (
    <PreferencesContext.Provider
      value={{
        densityPreference,
        temperaturePreference,
        pressurePreference,

        getDensity,
        getDensityUnit,
        getDensityString,
        getSpecificGravity,

        getFermentationActivity,
        getFermentationActivityUnit,
        getFermentationActivityString,

        getTemperature,
        getTemperatureUnit,
        getTemperatureString,

        getPressure,
        getPressureUnit,
        getPressureString,
        getPSI,
      }}
    >
      {children}
    </PreferencesContext.Provider>
  );
};

export const usePreferences = () => useContext(PreferencesContext);
