import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';

import 'prismjs/prism';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-jsx';
import 'nprogress/nprogress.css';

import 'src/assets/css/prism.css';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import { enableES5 } from 'immer';
import * as serviceWorker from 'src/serviceWorker';
import store from 'src/store';
import { SettingsProvider } from 'src/contexts/SettingsContext';
import App from 'src/App';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import packageJson from '../package.json';
import { appConfig } from 'src/config';


Bugsnag.start({
  apiKey: '6513c2213bfee257d01ca32c7f74d970',
  plugins: [new BugsnagPluginReact()],
  appVersion: packageJson.version,
  enabledReleaseStages: ['production', 'staging'],
  releaseStage: appConfig.environment
})



const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React)


enableES5();

ReactDOM.render(
  <Provider store={store}>
    <SettingsProvider>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </SettingsProvider>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.register();
