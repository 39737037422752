import React, { Fragment, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AdminGuard from 'src/components/AdminGuard';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import CustomerGuard from 'src/components/CustomerGuard';
import AnalysisLayout from 'src/layouts/AnalysisLayout';
import lazyWithRetry from 'src/utils/lazyWithRetry';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>
                  {route.routes ? renderRoutes(route.routes) : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazyWithRetry(() => import('src/views/errors/NotFoundView')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazyWithRetry(() => import('src/views/1-auth/LoginView')),
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazyWithRetry(() => import('src/views/1-auth/LoginView')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazyWithRetry(() => import('src/views/1-auth/RegisterView')),
  },
  {
    exact: true,
    path: '/register-unprotected',
    component: lazyWithRetry(() => import('src/views/1-auth/RegisterView')),
  },
  {
    exact: false,
    path: '/forgot-password',
    component: lazyWithRetry(() => import('src/views/1-auth/ForgotPasswordView')),
  },
  {
    exact: true,
    path: '/reset-password/:token/:userId',
    component: lazyWithRetry(() => import('src/views/1-auth/ResetPasswordView')),
  },
  {
    exact: true,
    path: '/invitation/:inviteToken',
    component: lazyWithRetry(() => import('src/views/1-auth/AcceptInvitationView')),
  },
  {
    exact: true,
    path: '/invitation/register/:inviteToken',
    component: lazyWithRetry(() =>
      import('src/views/1-auth/AcceptInvitationView/InvitationNoAuth')
    ),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register/:id',
    component: lazyWithRetry(() => import('src/views/2-customer-views/client/RegisterClient')),
  },
  {
    path: '/app',
    guard: AuthGuard,
    routes: [
      {
        exact: true,
        path: '/app/auth-error',
        component: lazyWithRetry(() => import('src/views/1-auth/AuthErrorView')),
      },
      {
        path: '/app/analytics',
        guard: CustomerGuard,
        layout: AnalysisLayout,
        component: lazyWithRetry(() =>
          import('src/views/2-customer-views/batches/BatchComparison/index')
        ),
      },
      {
        guard: CustomerGuard,
        layout: DashboardLayout,
        routes: [
          {
            exact: true,
            path: '/app/account/:tab?',
            component: lazyWithRetry(() => import('src/views/2-customer-views/AccountView')),
          },
          {
            exact: true,
            path: '/app/recipes',
            component: lazyWithRetry(() =>
              import('src/views/2-customer-views/recipes/RecipeListView')
            ),
          },
          {
            exact: true,
            path: '/app/recipes/create',
            component: lazyWithRetry(() =>
              import('src/views/2-customer-views/recipes/RecipeCreateEditView')
            ),
          },
          {
            exact: true,
            path: '/app/recipes/:recipeId',
            component: lazyWithRetry(() =>
              import('src/views/2-customer-views/recipes/RecipeDetailsView')
            ),
          },
          {
            exact: true,
            path: '/app/recipes/:recipeId/edit',
            component: lazyWithRetry(() =>
              import('src/views/2-customer-views/recipes/RecipeCreateEditView')
            ),
          },
          {
            exact: true,
            path: '/app/batches',
            component: lazyWithRetry(() =>
              import('src/views/2-customer-views/batches/BatchListView')
            ),
          },
          {
            exact: true,
            path: '/app/batches/create',
            component: lazyWithRetry(() =>
              import('src/views/2-customer-views/batches/BatchCreateView')
            ),
          },
          {
            exact: true,
            path: '/app/batches/:batchId',
            component: lazyWithRetry(() =>
              import('src/views/2-customer-views/batches/BatchDetailsView')
            ),
          },
          {
            exact: true,
            path: '/app/batches/:batchId/add-device',
            component: lazyWithRetry(() =>
              import('src/views/2-customer-views/batches/AddDeviceToBatchView')
            ),
          },
          {
            exact: true,
            path: '/app/batches/:batchId/edit',
            component: lazyWithRetry(() =>
              import('src/views/2-customer-views/batches/BatchEditView')
            ),
          },
          {
            exact: true,
            path: '/app/devices',
            component: lazyWithRetry(() =>
              import('src/views/2-customer-views/devices/DeviceListView')
            ),
          },
          {
            exact: true,
            path: '/app/devices/:deviceId',
            component: lazyWithRetry(() =>
              import('src/views/2-customer-views/devices/DeviceDetailsView')
            ),
          },
          {
            exact: true,
            path: '/app/devices/:deviceId/edit',
            component: lazyWithRetry(() =>
              import('src/views/2-customer-views/devices/DeviceEditView')
            ),
          },
          {
            exact: true,
            path: '/app/ingredients/fermentables',
            component: lazyWithRetry(() =>
              import('src/views/2-customer-views/fermentables/FermentableListView')
            ),
          },
          {
            exact: true,
            path: '/app/ingredients/fermentables/:fermentableId',
            component: lazyWithRetry(() =>
              import('src/views/2-customer-views/fermentables/FermentableDetailsView')
            ),
          },
          {
            exact: true,
            path: '/app/ingredients/fermentables/create',
            component: lazyWithRetry(() =>
              import('src/views/2-customer-views/fermentables/FermentableCreateView')
            ),
          },
          {
            exact: true,
            path: '/app/ingredients/fermentables/:fermentableId/edit',
            component: lazyWithRetry(() =>
              import('src/views/2-customer-views/fermentables/FermentableEditView')
            ),
          },
          {
            exact: true,
            path: '/app/client/:clientId/invite',
            component: lazyWithRetry(() =>
              import('src/views/2-customer-views/client/ClientInviteView')
            ),
          },
          {
            exact: true,
            path: '/app/dashboard',
            component: lazyWithRetry(() => import('src/views/2-customer-views/dashboard')),
          },

          {
            exact: true,
            path: '/app',
            component: () => <Redirect to="/app/dashboard" />,
          },
          {
            path: '/app/management',
            guard: AdminGuard,
            routes: [
              {
                exact: true,
                path: '/app/management/clients',
                component: lazyWithRetry(() =>
                  import('src/views/3-admin-views/client/ClientListView')
                ),
              },
              {
                exact: true,
                path: '/app/management/clients/create',
                component: lazyWithRetry(() =>
                  import('src/views/3-admin-views/client/ClientCreateView')
                ),
              },
              {
                exact: true,
                path: '/app/management/clients/:clientId',
                component: lazyWithRetry(() =>
                  import('src/views/3-admin-views/client/ClientDetailsView')
                ),
              },
              {
                exact: true,
                path: '/app/management/clients/:clientId/edit',
                component: lazyWithRetry(() =>
                  import('src/views/3-admin-views/client/ClientEditView')
                ),
              },
              {
                exact: true,
                path: '/app/management/users',
                component: lazyWithRetry(() => import('src/views/3-admin-views/user/UserListView')),
              },
              {
                exact: true,
                path: '/app/management/users/:userId',
                component: lazyWithRetry(() =>
                  import('src/views/3-admin-views/user/UserDetailsView')
                ),
              },
              {
                exact: true,
                path: '/app/management/users/:userId/edit',
                component: lazyWithRetry(() => import('src/views/3-admin-views/user/UserEditView')),
              },
              {
                exact: true,
                path: '/app/management/devices',
                component: lazyWithRetry(() =>
                  import('src/views/3-admin-views/device/DeviceListView')
                ),
              },
              {
                exact: true,
                path: '/app/management/devices/bulk-upload',
                component: lazyWithRetry(() =>
                  import('src/views/3-admin-views/device/DeviceBulkUploadView')
                ),
              },
              {
                exact: true,
                path: '/app/management/devices/create',
                component: lazyWithRetry(() =>
                  import('src/views/3-admin-views/device/DeviceCreateView')
                ),
              },
              {
                exact: true,
                path: '/app/management/devices/:deviceId',
                component: lazyWithRetry(() =>
                  import('src/views/3-admin-views/device/DeviceDetailsView')
                ),
              },
              {
                exact: true,
                path: '/app/management/devices/:deviceId/edit',
                component: lazyWithRetry(() =>
                  import('src/views/3-admin-views/device/DeviceEditView')
                ),
              },
              {
                exact: true,
                path: '/app/management/production-batches',
                component: lazyWithRetry(() =>
                  import('src/views/3-admin-views/production-batch/ProductionBatchListView')
                ),
              },
              {
                exact: true,
                path: '/app/management/production-batches/create',
                component: lazyWithRetry(() =>
                  import('src/views/3-admin-views/production-batch/ProductionBatchUploadView')
                ),
              },
              {
                exact: true,
                path: '/app/management/production-batches/:productionBatchId',
                component: lazyWithRetry(() =>
                  import('src/views/3-admin-views/production-batch/ProductionBatchDetailsView')
                ),
              },
              {
                exact: true,
                path: '/app/management/cloud-connects',
                component: lazyWithRetry(() =>
                  import('src/views/3-admin-views/cloud-connect/CloudConnectListView')
                ),
              },
              {
                exact: true,
                path: '/app/management/cloud-connects/bulk-upload',
                component: lazyWithRetry(() =>
                  import('src/views/3-admin-views/cloud-connect/CloudConnectBulkUploadView')
                ),
              },
              {
                exact: true,
                path: '/app/management/cloud-connects/create',
                component: lazyWithRetry(() =>
                  import('src/views/3-admin-views/cloud-connect/CloudConnectCreateView')
                ),
              },
              {
                exact: true,
                path: '/app/management/cloud-connects/:cloudConnectId',
                component: lazyWithRetry(() =>
                  import('src/views/3-admin-views/cloud-connect/CloudConnectDetailsView')
                ),
              },
              {
                exact: true,
                path: '/app/management/cloud-connects/:cloudConnectId/edit',
                component: lazyWithRetry(() =>
                  import('src/views/3-admin-views/cloud-connect/CloudConnectEditView')
                ),
              },
              {
                exact: true,
                path: '/app/management/device-logs',
                component: lazyWithRetry(() =>
                  import('src/views/3-admin-views/device/device-logs')
                ),
              },

              {
                exact: true,
                path: '/app/management/device-types',
                component: lazyWithRetry(() =>
                  import('src/views/3-admin-views/device/device-type/DeviceTypeListView')
                ),
              },
              {
                exact: true,
                path: '/app/management/device-types/create',
                component: lazyWithRetry(() =>
                  import('src/views/3-admin-views/device/device-type/DeviceTypeCreateView')
                ),
              },
              {
                exact: true,
                path: '/app/management/device-types/:deviceTypeId',
                component: lazyWithRetry(() =>
                  import('src/views/3-admin-views/device/device-type/DeviceTypeDetailsView')
                ),
              },
              {
                exact: true,
                path: '/app/management/device-types/:deviceTypeId/edit',
                component: lazyWithRetry(() =>
                  import('src/views/3-admin-views/device/device-type/DeviceTypeEditView')
                ),
              },
              {
                exact: true,
                path: '/app/management/device-types/:deviceTypeId/device-variants/create',
                component: lazyWithRetry(() =>
                  import('src/views/3-admin-views/device/device-variant/DeviceVariantCreateView')
                ),
              },
              {
                exact: true,
                path: '/app/management/device-variants/:deviceVariantId',
                component: lazyWithRetry(() =>
                  import('src/views/3-admin-views/device/device-variant/DeviceVariantDetailsView')
                ),
              },
              {
                exact: true,
                path: '/app/management/device-variants/:deviceVariantId/edit',
                component: lazyWithRetry(() =>
                  import('src/views/3-admin-views/device/device-variant/DeviceVariantEditView')
                ),
              },
              {
                exact: true,
                path: '/app/management/bug-report/',
                component: lazyWithRetry(() => import('src/views/3-admin-views/bug-report')),
              },
              {
                exact: true,
                path: '/app/management/feature-request/',
                component: lazyWithRetry(() => import('src/views/3-admin-views/feature-request')),
              },
              {
                exact: true,
                path: '/app/management/subscriptions/:id',
                component: lazyWithRetry(() =>
                  import('src/views/3-admin-views/subscription/SubscriptionDetailsView')
                ),
              },
              {
                exact: true,
                path: '/app/management',
                component: () => <Redirect to="/app/management/clients" />,
              },
            ],
          },
        ],
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },

  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        // component: HomeView
        component: () => <Redirect to="/login" />,
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
];

export default routes;
