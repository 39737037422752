import jwtDecode from 'jwt-decode';
import logger from 'src/utils/logger';

const accessKey = 'accessToken';
const refreshKey = 'refreshToken';

const getUser = async () => {
  const token = getAccessToken();
  return token ? jwtDecode(token) : null;
};

const storeAccessToken = accessToken => {
  try {
    localStorage.setItem(accessKey, accessToken);
  } catch (error) {
    logger.error(error);
  }
};

const getAccessToken = () => {
  try {
    return localStorage.getItem(accessKey);
  } catch (error) {
    logger.error(error);
  }
};

const removeAccessToken = () => {
  try {
    localStorage.removeItem(accessKey);
  } catch (error) {
    logger.error(error);
  }
};

const storeRefreshToken = refreshToken => {
  try {
    localStorage.setItem(refreshKey, refreshToken);
  } catch (error) {
    logger.error(error);
  }
};

const getRefreshToken = () => {
  try {
    return localStorage.getItem(refreshKey);
  } catch (error) {
    logger.error(error);
  }
};

const removeRefreshToken = () => {
  try {
    localStorage.removeItem(refreshKey);
  } catch (error) {
    logger.error(error);
  }
};

export default {
  getUser,
  getAccessToken,
  removeAccessToken,
  storeAccessToken,
  getRefreshToken,
  removeRefreshToken,
  storeRefreshToken,
};
