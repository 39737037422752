import { useRef, useEffect } from 'react';
import dayjs from 'dayjs';
import axios from 'src/utils/axios';

export default function useAppUsage(history) {
  const usageStartTime = useRef(dayjs());

  useEffect(() => {
    const handleFocus = () => {
      usageStartTime.current = dayjs();
    };
    const handleBlur = () => {
      const usage = dayjs().diff(usageStartTime.current, 'seconds');

      if (history?.location?.pathname?.includes('/app')) {
        const cappedUsage = Math.min(usage, 600);
        axios.post('/users/usage/webapp', { time: cappedUsage });
      }
    };

    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);

    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
    };
  }, []);
}
