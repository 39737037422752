import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import Bugsnag from '@bugsnag/js';
import jwtDecode from 'jwt-decode';

import apiClient, { authApi } from '@plaato/api-client';
import authStorage from './secureStorage';
import axios from 'src/utils/axios';

const AuthContext = createContext({});

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingInitial, setLoadingInitial] = useState(true);
  const [error, setError] = useState(undefined);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    apiClient.setStoreTokensFunction(_setTokens);
    async function init() {
      const { accessToken, refreshToken } = await _retrieveTokens();

      if (accessToken && refreshToken) {
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        await _setTokensAndUpdateApi(accessToken, refreshToken);
      } else {
        await _setTokensAndUpdateApi(undefined, undefined);
        delete axios.defaults.headers.common.Authorization;
      }

      setLoadingInitial(false);
    }

    void init();
  }, []);

  useEffect(() => {
    if (user) Bugsnag.setUser(user.id, user.email, user.name);
  }, [user]);

  async function _setTokens(accessToken, refreshToken) {
    if (accessToken && refreshToken) {
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      _storeTokens(accessToken, refreshToken);
      await _updateUser(accessToken);
      setIsAuthenticated(true);
    } else {
      await _removeTokens();
      await _updateUser(undefined);
      delete axios.defaults.headers.common.Authorization;
      setIsAuthenticated(false);
    }
  }

  async function _setTokensAndUpdateApi(accessToken, refreshToken) {
    await apiClient.setTokens(accessToken, refreshToken);
    await _setTokens(accessToken, refreshToken);
  }

  function _storeTokens(accessToken, refreshToken) {
    authStorage.storeAccessToken(accessToken);
    authStorage.storeRefreshToken(refreshToken);
  }

  async function _removeTokens() {
    authStorage.removeAccessToken();
    authStorage.removeRefreshToken();
  }

  async function _updateUser(accessToken) {
    const user = accessToken ? await jwtDecode(accessToken) : null;
    setUser(user);
    setIsAdmin(user?.isAdmin ?? false);
  }

  async function _retrieveTokens() {
    const accessToken = authStorage.getAccessToken();
    const refreshToken = authStorage.getRefreshToken();
    return { accessToken, refreshToken };
  }

  async function login(email, password, recaptchaToken) {
    setLoading(true);
    apiClient.setStoreTokensFunction(_setTokens);
    const { ok, data } = await authApi.login({ email, password, recaptchaToken });

    if (ok && data?.accessToken && data?.refreshToken) {
      const { accessToken, refreshToken } = await data;
      await _setTokensAndUpdateApi(accessToken, refreshToken);
    } else {
      setError(data?.error?.message);
      await _setTokensAndUpdateApi(undefined, undefined);
    }
    setLoading(false);
    return;
  }

  const register = async (email, name, password, recaptchaToken) => {
    setLoading(true);

    const response = await axios.post('/users', {
      email,
      name,
      password,
      recaptchaToken,
    });
    const data = response.data;
    const ok = response.status == 200 ? true : false;

    if (ok && data?.accessToken && data?.refreshToken) {
      const { accessToken, refreshToken } = await data;
      await _setTokensAndUpdateApi(accessToken, refreshToken);
    } else {
      setError(data?.error?.message);
      await _setTokensAndUpdateApi(undefined, undefined);
    }
    setLoading(false);
    return;
  };

  async function logout() {
    setLoading(true);
    await _setTokensAndUpdateApi(undefined, undefined);
    setLoading(false);
  }

  async function refreshUser() {
    setLoading(true);
    const refreshToken = authStorage.getRefreshToken();
    const { status, data, problem } = await authApi.refreshToken({ refreshToken });
    if (status === 200) {
      const { accessToken, refreshToken } = data;
      await _setTokensAndUpdateApi(accessToken, refreshToken);
    } else {
      setError('Failed to refresh user');
      if (problem === 'CLIENT_ERROR') {
        await _setTokensAndUpdateApi(undefined, undefined);
      }
    }
    setLoading(false);
  }

  const clientRegistration = async (email, name, password, recaptchaToken, clientId = null) => {
    setLoading(true);
    try {
      const response = await axios.post('/users/client-registration', {
        email,
        name,
        password,
        clientId,
        recaptchaToken,
      });
      const { accessToken, refreshToken } = response?.data;

      if (accessToken && refreshToken) {
        await _setTokensAndUpdateApi(accessToken, refreshToken);
      } else {
        setError(response?.error?.message);
        await _setTokensAndUpdateApi(undefined, undefined);
      }
    } catch (err) {
      setError(err?.response?.data?.error?.message);
    }

    setLoading(false);
  };

  const memoedValues = useMemo(
    () => ({
      user,
      loading,
      error,
      method: 'JWT',
      isAdmin,
      isAuthenticated,
      register,
      login,
      logout,
      clientRegistration,
      refreshUser,
    }),
    [user, isAuthenticated, isAdmin, loading, error]
  );

  return (
    <AuthContext.Provider value={memoedValues}>
      {!loadingInitial ? children : null}
    </AuthContext.Provider>
  );
}

export default function useAuth() {
  return useContext(AuthContext);
}
