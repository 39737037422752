import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

const CustomerGuard = ({ children }) => {
  const { user, isAdmin } = useAuth();

  if (!user?.clientId && !isAdmin) {
    return <Redirect to="/app/auth-error" />;
  }
  return <>{children}</>;
};

CustomerGuard.propTypes = {
  children: PropTypes.node,
};

export default CustomerGuard;
