import { useEffect, useState } from 'react';

import { clientApi } from '@plaato/api-client';
import useApi from './useApi';
import Client from '../models/client';

export default function useClient(clientId) {
  const [client, setClient] = useState(undefined);
  const { request, data, loading, error } = useApi(clientApi.fetchById);

  useEffect(refresh, [clientId]);

  useEffect(() => {
    if (data) setClient(new Client(data));
    else setClient(undefined);
  }, [data]);

  function refresh() {
    if (clientId) request(clientId);
    else setClient(undefined);
  }

  return { client, refresh, loading, error };
}
