import getHerokuRuntimeEnvs from '@mars/heroku-js-runtime-env';

function getEnvironmentVariables() {
  // Fetch Heroku runtime envs. Returns an empty object ('{}') if none are retrieved
  let env = getHerokuRuntimeEnvs();
  // If the object is empty (not on Heroku or no variables set), assume vars are set by the ./env.sh script
  if (env && Object.keys(env).length === 0 && Object.getPrototypeOf(env) === Object.prototype) {
    // The file created in the ./env.sh script is loaded in './public/index.html'
    // This file, 'env-config.js', makes environment variables accessible through 'window._env_'
    env = window._env_;
  }
  return env;
}

const env = getEnvironmentVariables();

export const appConfig = {
  environment: env.REACT_APP_ENVIRONMENT,
  apiBaseUrl: env.REACT_APP_API_BASE_URL,
  recaptchaSiteKey: env.REACT_APP_RECAPTCHA_SITE_KEY,
  intercomKey: env.REACT_APP_INTERCOM_KEY,
};
