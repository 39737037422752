export default {
  h1: {
    fontWeight: 500,
    fontSize: 35,
    letterSpacing: '-0.24px',
  },
  h2: {
    fontWeight: 700,
    fontSize: 29,
    letterSpacing: '-0.24px',
  },
  h3: {
    fontWeight: 700,
    fontSize: 24,
    letterSpacing: '-0.06px',
  },
  h4: {
    fontWeight: 500,
    fontSize: 20,
    letterSpacing: '-0.06px',
  },
  h5: {
    fontWeight: 700,
    fontSize: 18,
    letterSpacing: '-0.05px',
  },
  h6: {
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: '-0.05px',
  },
  overline: {
    fontWeight: 500,
  },
  ListHeader: {
    fontWeight: 400,
    fontSize: 14,
    letterSpacing: '-0.05px',
  },

  ListNameColumn: {
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: '-0.05px',
  },
  ListCell: {
    fontWeight: 400,
    fontSize: 14,
    letterSpacing: '-0.05px',
  },

  batchComparisonFilter: {
    fontWeight: 400,
    fontSize: 12,
    letterSpacing: '-0.05px',
  },
  batchFilter: {
    fontWeight: 400,
    fontSize: 12,
    letterSpacing: '-0.05px',
  }

};
