import React, { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import NavBar from './NavBar';
import TopBar from './TopBar';
import { Grid } from '@mui/material';
import { useTheme } from '@mui/styles';
import AnnouncementBar from '../../components/AnnouncementBar';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    width: '100%',
    display: 'flex',
  },
}));

const DashboardLayout = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={setMobileNavOpen} />
      <Grid
        id="dashboard-container"
        container
        spacing={theme.spacing(2)}
        paddingTop={theme.spacing(12)}
        paddingX={{ md: theme.spacing(0.5), lg: theme.spacing(5) }}
      >
        <Grid item xs={1} md={1} lg={2}>
          <NavBar onMobileClose={() => setMobileNavOpen(false)} openMobile={isMobileNavOpen} />
        </Grid>
        <Grid item xs={10} md={10} lg={8}>
          {children}
        </Grid>
      </Grid>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default DashboardLayout;
