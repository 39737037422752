import React, { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import TopBar from '../DashboardLayout/TopBar';
import NavBar from '../DashboardLayout/NavBar';
import { Grid } from '@mui/material';
import { useTheme } from '@mui/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    width: '100%',
    display: 'flex',
  },
}));

const AnalysisLayout = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <div className={classes.root}>
      
      <TopBar onMobileNavOpen={setMobileNavOpen} />
    
      <Grid
        id="dashboard-container"
        container
        spacing={2}
        paddingTop={theme.spacing(11)}
        paddingLeft={{ lg: theme.spacing(5) }}
      >
        <Grid item xs={1} md={1} lg={2}>
          <NavBar onMobileClose={() => setMobileNavOpen(false)} openMobile={isMobileNavOpen} />
        </Grid>
        <Grid item xs={11} md={11} lg={10}>
          {children}
        </Grid>
      </Grid>
    </div>
  );
};

AnalysisLayout.propTypes = {
  children: PropTypes.node,
};

export default AnalysisLayout;
