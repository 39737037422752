import React from 'react';

export default function PlaatoProfileIcon({ size = 87 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 87 87"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="-7.70874" y="-7.70898" width="101.316" height="101.316" fill="#1B1D29" />
      <g clipPath="url(#clip0_350_4576)">
        <path
          d="M49.2306 26.3511C47.0246 26.3511 44.8201 26.3497 42.6155 26.3477V32.82H49.2306C57.9227 32.82 57.9729 47.6117 49.2306 47.6117H38.9855V42.6705H32.1572V62.619H38.9862V53.8828H49.2313C66.9773 53.8828 66.925 26.3511 49.2306 26.3511Z"
          fill="white"
        />
        <path
          d="M33.6796 24.6023C31.1642 22.0821 27.0849 22.0821 24.5687 24.6023C22.0526 27.1226 22.0526 31.2086 24.5687 33.7281C27.0849 36.2477 31.1642 36.2484 33.6796 33.7281C36.1958 31.2079 36.1958 27.1226 33.6796 24.6023ZM29.1239 32.1781C27.4629 32.1781 26.1156 30.8292 26.1156 29.1649C26.1156 27.5006 27.4623 26.1518 29.1239 26.1518C30.7854 26.1518 32.1321 27.5006 32.1321 29.1649C32.1321 30.8292 30.7848 32.1781 29.1239 32.1781Z"
          fill="#F49D37"
        />
      </g>
      <defs>
        <clipPath id="clip0_350_4576">
          <rect
            width="40.808"
            height="40.1044"
            fill="white"
            transform="translate(22.5454 22.5452)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
