import { Box } from '@mui/material';
import PrimaryButton from 'src/components/Button';
import { Link as RouterLink, useHistory } from 'react-router-dom';

const AdminButtons = ({ isAdmin, showCustomerInterface, setShowCustomerInterface }) => {
  const history = useHistory();

  const toggleView = () => {
    if (showCustomerInterface) {
      history.push('/app/management/clients');
    } else {
      history.push('/app/dashboard');
    }
    setShowCustomerInterface(!showCustomerInterface);
  };

  return (
    <>
      {isAdmin && (
        <Box p={2} textAlign="center" sx={{ justifyContent: 'center', display: 'flex' }}>
          <PrimaryButton onClick={toggleView} type="submit">
            {showCustomerInterface ? <>Admin view</> : <>Customer view</>}
          </PrimaryButton>
        </Box>
      )}
    </>
  );
};

export default AdminButtons;
