import React from 'react';

export default function PlaatoAddIcon({ size = 7 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 7 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.40809 2.90809H4.09192V0.591912C4.09192 0.434927 4.02956 0.284372 3.91855 0.173367C3.80755 0.0623621 3.65699 0 3.50001 0C3.34302 0 3.19247 0.0623621 3.08146 0.173367C2.97046 0.284372 2.90809 0.434927 2.90809 0.591912V2.90809H0.591912C0.434927 2.90809 0.284372 2.97045 0.173367 3.08146C0.0623621 3.19246 0 3.34302 0 3.5C0 3.65699 0.0623621 3.80754 0.173367 3.91855C0.284372 4.02955 0.434927 4.09192 0.591912 4.09192H2.90809V6.40809C2.90809 6.56507 2.97046 6.71563 3.08146 6.82663C3.19247 6.93764 3.34302 7 3.50001 7C3.65699 7 3.80755 6.93764 3.91855 6.82663C4.02956 6.71563 4.09192 6.56507 4.09192 6.40809V4.09192H6.40809C6.56507 4.09192 6.71563 4.02955 6.82663 3.91855C6.93764 3.80754 7 3.65699 7 3.5C7 3.34302 6.93764 3.19246 6.82663 3.08146C6.71563 2.97045 6.56507 2.90809 6.40809 2.90809Z"
        fill="black"
      />
    </svg>
  );
}
