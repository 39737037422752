import { useState } from 'react';

export default class Dto {
  constructor(obj) {
    if (obj) {
      Object.assign(this, obj)
    }
  }

  static beforeRequest(params) {
    if (params && params.setLoading) params.setLoading(true);
  }
  static afterRequest(params, response) {
    if (params && params.setLoading) params.setLoading(false);
    if (params && params.setError) params.setError(!response.ok);
  }

  toString() {
    Object.keys(this).forEach(k => {
      if (typeof this[k] === 'object') {
        return toString(this[k]);
      }

      this[k] = '' + this[k];
    });

    return this;
  }
}