import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  SvgIcon,
  Toolbar,
  Grid,
  Divider,
  Button,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'src/components/Logo';
import { THEMES } from 'src/constants';
import Account from './Account';
import AnnounceKitWidget from 'src/components/AnnounceKitWidget';
import AnnouncementBar from '../../../components/AnnouncementBar';
import useAuth from 'src/contexts/JWTAuthContext';
import useClient from 'src/hooks/useClient';
import PrimaryButton from 'src/components/Button';
import axiosInstance from 'src/utils/axios';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: 'none',
          backgroundColor: theme.palette.primary.main,
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          boxShadow: 'none',
          backgroundColor: theme.palette.background.dark,
        }
      : {}),
  },
  toolbar: {
    minHeight: 64,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 0,
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.dark,
        }
      : {}),
  },
  lowerHeader: {
    padding: '0 24px',
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const [navOpen, setNavOpen] = useState(false);
  const { user, refreshUser } = useAuth();
  const { client, refresh: refreshClient } = useClient(user?.clientId);

  const logOutOfClient = async () => {
    const url = `/admin/users/${user.id}`;
    const body = {
      clientId: process.env.REACT_APP_ADMIN_CLIENT_ID,
    };

    // Open link in new tab
    await axiosInstance.patch(url, body);
    await refreshUser();
    refreshClient();
    window.location.reload();
  };

  useEffect(() => {
    onMobileNavOpen(navOpen);
  }, [navOpen]);
  return (
    <>
      <AppBar className={clsx(classes.root, className)} {...rest}>
        <Toolbar className={classes.toolbar}>
          <AnnouncementBar />
          {user.isAdmin && user.clientId !== process.env.REACT_APP_ADMIN_CLIENT_ID ? (
            <div
              style={{
                backgroundColor: 'red',

                display: 'flex',
                width: '100%',
                padding: 10,
                color: 'black',
              }}
            >
              <div style={{ margin: 'auto' }}>
                You are logged in as {client?.name}
                <PrimaryButton style={{ marginLeft: 10 }} type="button" onClick={logOutOfClient}>
                  Log out of client
                </PrimaryButton>
              </div>
            </div>
          ) : (
            ''
          )}
          <Grid container className={classes.lowerHeader} columns={14} alignItems="center">
            <Grid item xs={2}>
              <Hidden lgUp>
                <IconButton
                  color="inherit"
                  id="burger-menu"
                  onClick={() => setNavOpen(!navOpen)}
                  size="large"
                >
                  <SvgIcon fontSize="small">
                    <MenuIcon />
                  </SvgIcon>
                </IconButton>
              </Hidden>
            </Grid>
            <Grid item xs={10} align="center">
              <RouterLink to="/">
                <Logo style={{ width: 180 }} />
              </RouterLink>
            </Grid>
            <Grid item xs={1} align="end" style={{ flex: 1 }}>
              <AnnounceKitWidget />
            </Grid>
            <Grid item xs={1} align="end" style={{ flex: 1 }}>
              <Account />
            </Grid>
          </Grid>
        </Toolbar>
        <Divider />
      </AppBar>
    </>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;
