import _ from 'lodash';
import { responsiveFontSizes } from '@mui/material';
import { THEMES } from 'src/constants';
import { strongShadows } from './shadows';
import typography from './typography';
import { createTheme } from '@mui/material/styles';

const baseOptions = {
  direction: 'ltr',
  typography,
  breakpoints: {
    values: {
      xs: 0,
      sm: 800,
      md: 1000,
      lg: 1400,
      xl: 1500,
    },
  },

  components: {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 32,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0,0,0,0.075)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          textTransform: 'none',
        },
      },
      variants: [
        {
          props: { variant: 'backButton' },
          style: {
            backgroundColor: '#15171F80',
            color: '#FFFFFF',
          },
        },
        {
          props: { variant: 'deleteButton' },
          style: {
            border: `1px solid #FF1D51`,
            color: '#FF1D51',
          },
        },
      ],
    },

    // This controls the border radius of the text field
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 6,
        },
      },
    },

    /***  Cards */
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 6,
        },
      },
      variants: [
        {
          props: { variant: 'statBox' },
          style: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          },
        },
        {
          props: { variant: 'filterTable' },
          style: {},
        },
        {
          props: { variant: 'root' },
          style: {},
        },
        {
          props: { variant: 'navBar' },
          style: ({ theme }) => ({
            [theme.breakpoints.up('md')]: {
              position: 'fixed',
            },
          }),
        },
      ],
    },
    MuiCardContent: {
      styleOverrides: {
        root: ({ theme }) => ({
          paddingTop: theme.spacing(3),
          paddingBottom: theme.spacing(3),
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        }),
      },
    },

    MuiTabs: {
      styleOverrides: {
        root: ({ theme }) => ({
          minHeight: 0,
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          textTransform: 'none',
          ...typography.h6,
          paddingTop: 0,
          paddingBottom: theme.spacing(1),
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
          color: theme.palette.text.primary,
          minWidth: 0,
          minHeight: 0,
        }),
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&:hover': {
            backgroundColor: theme.palette.primary.main + '1A' + ' !important',
          },
          '&.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: theme.palette.primary.main + '1A' + ' !important',
          },
        }),
      },
      variants: [
        {
          props: { variant: 'clickable' },
          style: ({ theme }) => ({
            '&:hover': {
              backgroundColor: theme.palette.primary.main + '1A' + ' !important',
              cursor: 'pointer',
            },
          }),
        },
      ],
    },
    MuiTableCell: {
      styleOverrides: {
        head: ({ theme }) => ({
          paddingBottom: theme.spacing(0.5),
          paddingTop: theme.spacing(1),
          height: theme.spacing(2),
        }),
        root: ({ theme }) => ({
          paddingBottom: theme.spacing(1.5),
          paddingTop: theme.spacing(2),
          paddingLeft: theme.spacing(2),
          height: theme.spacing(7.5),
        }),
      },
      variants: [
        {
          props: { variant: 'img' },
          style: {
            width: '5%',
          },
        },
      ],
    },
    MuiAvatar: {
      styleOverrides: {
        root: ({ theme }) => ({
          height: 32,
          width: 32,
        }),
      },
      variants: [
        {
          props: { variant: 'topBar' },
          style: ({ theme }) => ({
            marginLeft: theme.spacing(2),
            marginBottom: theme.spacing(1),
          }),
        },
        {
          props: { variant: 'tableRow' },
          style: ({ theme }) => ({
            marginLeft: theme.spacing(2),
          }),
        },
      ],
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'tableHead' },
          style: ({ theme }) => ({
            ...typography.h6,
            color: theme.palette.text.secondary,
          }),
        },
        {
          props: { variant: 'fieldTitle' },
          style: ({ theme }) => ({
            ...typography.h5,
          }),
        },
      ],
    },
    // Homemade
    TemperatureIcon: {
      root: {
        width: 10,
        height: 18,
      },
    },
  },
};

const themesOptions = [
  {
    name: THEMES.ONE_DARK,
    palette: {
      mode: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)',
      },
      background: {
        dark: '#1b1e2a',
        paper: '#333646',
        header: '#272937',
      },
      primary: {
        main: '#f49d37',
      },
      secondary: {
        main: '#f49d37',
      },
      info: {
        main: '#1b1e2a',
      },
      text: {
        primary: '#e6e5e8',
        secondary: '#adb0bb',
        dark: 'rgb(0, 0, 0)',
        white: '#FFFFFF',
        error: '#FF4952',
      },
      button: {
        info: '#e6e5e8',
        secondary: '#adb0bb',
        delete: '#FF4952',
        disabled: '#000000',
      },
      linearProgress: {
        full: '#19C298',
        threeThird: '#F49D37',
        twoThird: '#BE75E4',
        oneThird: '#3C77EB',
      },
      stabilising: {
        offline: '#5F2A35',
        inAir: '#253863',
        main: '#1A4F4A',
      },
      deviceIndicator: {
        red: '#FF4952',
        blue: '#3C77EB',
        green: '#19C298',
      },
      charts: {
        gridLineY: '#FFFFFF1A',
      },
      density: {
        primary: '#f49d37',
      },
      temperature: {
        primary: '#68A691',
      },
      fermentationActivity: {
        primary: '#BE75E4',
      },
    },

    shadows: strongShadows,
  },
];

export const createMyTheme = (config = {}) => {
  let themeOptions = themesOptions.find(theme => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createTheme(_.merge({}, baseOptions, themeOptions, { direction: config.direction }));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
