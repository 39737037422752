/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Avatar, Box, Drawer, Card, Hidden, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { appConfig } from 'src/config';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import { useTheme } from '@mui/styles';
import MenuOptions from './MenuOptions';
import AdminButtons from './AdminButtons';
import PlaatoAddIcon from 'src/components/icons/PlaatoAddIcon';
import PlaatoProfileIcon from 'src/components/icons/PlaatoProfileIcon';

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 150,
  },
  desktopDrawer: {
    width: 150,
    top: 64,
  },
  avatar: {
    cursor: 'pointer',
    width: 130,
    height: 130,
  },
}));

const EnvBar = () => {
  /*
    Displays a purple bar with the environment name in non-Production environment
    Can be removed by clicking on it
   */
  const environment = appConfig.environment;
  const [show, setShow] = useState(true);

  if (environment && environment.toUpperCase() !== 'PRODUCTION') {
    return (
      show && (
        <Box pb={2}>
          <Box
            p={1}
            bgcolor={'#5A2283'}
            textAlign={'center'}
            typography={{ textTransform: 'uppercase' }}
            sx={{ cursor: 'pointer' }}
            onClick={() => setShow(false)}
          >
            <Typography> {environment}</Typography>
          </Box>
        </Box>
      )
    );
  } else {
    return null;
  }
};

const NavBar = ({ onMobileClose, openMobile, ...rest }) => {
  const theme = useTheme();
  const classes = useStyles();
  const location = useLocation();
  const { user, isAdmin } = useAuth();

  const [showCustomerInterface, setShowCustomerInterface] = useState(false);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box display="flex" flexDirection="column">
      <Hidden lgUp>
        <Box p={2} display="flex" justifyContent="center">
          <RouterLink to="/">
            <Logo style={{ width: '180px' }} />
          </RouterLink>
        </Box>
      </Hidden>
      <EnvBar />
      <Box display="flex" justifyContent="center" pt={theme.spacing(5)}>
        <RouterLink to="/app/account">
          <Avatar alt="User" className={classes.avatar}>
            <PlaatoProfileIcon size={200} />
          </Avatar>
        </RouterLink>
      </Box>
      <Box display="flex" justifyContent="center" p={2}>
        <MenuOptions isAdmin={isAdmin} showCustomerInterface={showCustomerInterface} />
      </Box>

      <AdminButtons
        isAdmin={isAdmin}
        showCustomerInterface={showCustomerInterface}
        setShowCustomerInterface={setShowCustomerInterface}
      />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer onClose={onMobileClose} open={openMobile} variant="temporary">
          <Card variant="navBar">{content}</Card>
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Card sx={{ width: '200px' }} variant="navBar">
          {content}
        </Card>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
