import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Button } from '@mui/material';

const PrimaryButton = ({ icon, children, type, onClick, ...rest }) => {
  let routerLink = RouterLink;
  if (type == 'submit') routerLink = undefined;
  return (
    <Button
      color="secondary"
      variant="contained"
      component={routerLink}
      onClick={onClick}
      type={type}
      startIcon={icon}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default PrimaryButton;
